body {
  margin: 0;
  font-family: 'Avenir Next';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  // For printing QR Codes without requiring background content
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /* Firefox */
  forced-color-adjust: none !important; /* Edge */
}

html {
  height: 100vh;
}

#root {
  height: 100vh;
  width: 100vw;
  // Hack for mobile safari because it considers area under control bar in viewport
  max-height: -webkit-fill-available;
}

code {
  background-color: #f7f7f7;
}

// NOTE: fix styles for graphiql playground since we use
// border-box everywhere else
.graphiql-container * {
  box-sizing: content-box;
}

.react-icons {
  vertical-align: middle;
}

// styles for the stats panel
.fps > div {
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
  top: inherit !important;
  left: inherit !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    top: 18vh;
  }

  .Toastify__toast-container {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
  }
}

// react-toastify styles
.Toastify__toast {
  font-family: 'Avenir Next';
  border-radius: 0.2rem;
  // grayscale-800
  background-color: #222222;
  font-size: 16px;
}

.Toastify__toast--error {
  // ruby-600
  background-color: #d20023;
}

.Toastify__toast--info {
  // grayscale-800
  background-color: #222222;
}

// react-color styles
.flexbox-fix {
  display: none !important;
}

.emoji-mart-bar {
  display: none;
}

div.leaflet-div-icon {
  background: none;
  border: none;
}

.mapboxgl-ctrl {
  display: none !important;
}

// LAYOUT DEBUG CSS
// * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }
// * * * * {
//   background-color: rgba(255, 0, 255, 0.2);
// }
// * * * * * {
//   background-color: rgba(0, 255, 255, 0.2);
// }
// * * * * * * {
//   background-color: rgba(255, 255, 0, 0.2);
// }
// * * * * * * * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * * * * * * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * * * * * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }
