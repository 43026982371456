.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .message {
    color: rgb(53, 99, 180);
    font-size: 1.25rem;
    font-weight: 600;
  }

  .button {
    color: rgb(53, 99, 180);
    border: 1px solid rgb(53, 99, 180);
    margin-top: 1rem;
    padding: 0px 1rem;
    width: auto;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    outline: none;
    font-weight: 600;
    border-radius: 0.375rem;
    height: 2.5rem;
    font-size: 1rem;
  }
}
